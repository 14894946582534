import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "How to use debounce on input change with React-hook-form library",
  "description": "How to use debounce on input change with React-hook-form library",
  "date": "2020-08-16",
  "image": "forms-image.png",
  "tags": ["frontend", "javascript", "tutorial"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Recently, I've been using `}<a parentName="p" {...{
        "href": "https://github.com/react-hook-form/react-hook-form"
      }}>{`react-hook-form`}</a>{` to create a login form.
The library has a lot of `}<a parentName="p" {...{
        "href": "https://github.com/react-hook-form/react-hook-form/tree/master/examples"
      }}>{`examples`}</a>{` on the github page.
However, I could not find a case for my use. I wanted to use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`debounce`}</code>{` on inputChange to avoid trigger validation on every keystroke from users.
It took me a couple of days to get the result. Therefore, I decided to write this blog to share with anyone who wants to implement the same behavior`}</p>
    <p>{`The version of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`react-form-hook`}</code>{` mentioned in this blog is version 6.`}</p>
    <h2>{`Basic use of react-hook-form`}</h2>
    <p>{`The code below shows you the basic usage. The code is from `}<a parentName="p" {...{
        "href": "https://react-hook-form.com/get-started"
      }}>{`here`}</a>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` React `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` useForm `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react-hook-form'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`Example`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` handleSubmit`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` register`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` errors `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="span" {...{
              "className": "token function"
            }}>{`useForm`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="span" {...{
              "className": "token function-variable function"
            }}>{`onSubmit`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="span" {...{
              "className": "token parameter"
            }}>{`values`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` console`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`log`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`values`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span>{`form onSubmit`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`handleSubmit`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`onSubmit`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span>{`      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`input
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"email"`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        ref`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`register`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`          required`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`'Required'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`          pattern`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`            value`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token regex"
            }}>{`/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,}$/i`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`            message`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`'invalid email address'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`          `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`errors`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`email `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`p`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`errors`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`email`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`message`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`p`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`input
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"username"`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        ref`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`register`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`          `}<span parentName="span" {...{
              "className": "token function-variable function"
            }}>{`validate`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token parameter"
            }}>{`value`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` value`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`length `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span>{` `}<span parentName="span" {...{
              "className": "token number"
            }}>{`5`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`errors`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`username `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` errors`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`username`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`message`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`button type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"submit"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`Submit`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`button`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`form`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h2>{`Adding onChange handler for input fields`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` React `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` useForm `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react-hook-form'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`Example`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` handleSubmit`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` register`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` errors `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`useForm`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`onSubmit`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`values`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`values`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`form onSubmit`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`handleSubmit`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`onSubmit`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`input
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"email"`}</span>{`
        ref`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`register`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
          required`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Required'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          pattern`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token regex"
          }}>{`/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,}$/i`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            message`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'invalid email address'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        onChange`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` console`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`log`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`'email input changed'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`errors`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`email `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`p`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`errors`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`email`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`message`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`p`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`input
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"username"`}</span>{`
        ref`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`register`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
          `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`validate`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`value`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` value`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`length `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        onChange`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` console`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`log`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`'user input changed'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`errors`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`username `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` errors`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`username`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`message`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`button type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"submit"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`Submit`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`button`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`form`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <div {...{
      "style": {
        "textAlign": "center",
        "margin": "0 auto",
        "marginBottom": "20px"
      }
    }}>{`
    `}<img parentName="div" {...{
        "src": "/d9bb9c290ca524c14688b2f0b6d7041b/onChange.gif",
        "alt": "spinning-sun"
      }}></img>
    </div>
    <p>{`At this point, using `}<a parentName="p" {...{
        "href": "https://davidwalsh.name/javascript-debounce-function"
      }}>{`debounce`}</a>{` to only fire the callback after a certain amount of time is one of the good ways to improve client-side performance.
You can write a simple function to use, or you can install a small `}<a parentName="p" {...{
        "href": "https://github.com/component/debounce#readme"
      }}>{`debounce`}</a>{` package which I prefer.
This function will take a function as the first argument, and a wait time as the second argument.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` React `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` useForm `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react-hook-form'`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` debounce `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`'debounce'`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`Example`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` handleSubmit`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` register`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` errors `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`useForm`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`onSubmit`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`values`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`values`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`form onSubmit`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`handleSubmit`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`onSubmit`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`input
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"email"`}</span>{`
        ref`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`register`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
          required`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Required'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          pattern`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token regex"
          }}>{`/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,}$/i`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            message`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'invalid email address'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        onChange`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`debounce`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` console`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`log`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`'email input changed'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token number"
            }}>{`500`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`errors`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`email `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`p`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`errors`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`email`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`message`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`p`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`input
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"username"`}</span>{`
        ref`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`register`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
          `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`validate`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`value`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` value`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`length `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        onChange`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`debounce`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` console`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`log`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`'user input changed'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token number"
            }}>{`500`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`errors`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`username `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` errors`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`username`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`message`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`button type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"submit"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`Submit`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`button`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`form`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Now, the callback only triggers when users stop typing more than 500ms`}</p>
    <div {...{
      "style": {
        "textAlign": "center",
        "margin": "0 auto",
        "marginBottom": "20px"
      }
    }}>{`
    `}<img parentName="div" {...{
        "src": "/3ac52f039f02d82e15d84153527a2f5b/debounce.gif",
        "alt": "spinning-sun"
      }}></img>
    </div>
    <h2>{`Trigger validation`}</h2>
    <p>{`By default, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`react-form-hook`}</code>{` will trigger validation automatically based on our choice of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mode`}</code>{`,
but the library also exposes `}<a parentName="p" {...{
        "href": "https://react-hook-form.com/api/#trigger"
      }}>{`trigger`}</a>{` API where you can trigger the validation manually.
In our case, that is what we are going to use in our onChange handlers. Since the function returns a Promise, we need to use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`async/await`}</code>{` for the callback functions.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` React `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` useForm `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react-hook-form'`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` debounce `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`'debounce'`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`Example`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` handleSubmit`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` register`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` errors`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` trigger `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`useForm`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`onSubmit`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`values`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`values`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`form onSubmit`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`handleSubmit`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`onSubmit`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`input
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"email"`}</span>{`
        ref`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`register`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
          required`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Required'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          pattern`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token regex"
          }}>{`/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,}$/i`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            message`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'invalid email address'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        onChange`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`debounce`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token keyword"
            }}>{`async`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`          `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`await`}</span>{` `}<span parentName="span" {...{
              "className": "token function"
            }}>{`trigger`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`'email'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token number"
            }}>{`500`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`errors`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`email `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`p`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`errors`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`email`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`message`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`p`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`input
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"username"`}</span>{`
        ref`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`register`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
          `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`validate`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`value`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` value`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`length `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        onChange`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`debounce`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token keyword"
            }}>{`async`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`          `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`await`}</span>{` `}<span parentName="span" {...{
              "className": "token function"
            }}>{`trigger`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`'username'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token number"
            }}>{`500`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`errors`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`username `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` errors`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`username`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`message`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`button type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"submit"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`Submit`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`button`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`form`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h3>{`Why don't we use`}<code parentName="h3" {...{
        "className": "language-text"
      }}>{`onChange`}</code>{` mode in `}<code parentName="h3" {...{
        "className": "language-text"
      }}>{`useForm`}</code>{`?`}</h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`react-hook-form`}</code>{` has the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`onChange`}</code>{` mode which will automatically trigger validation when there is a change in the input fields. For some reason, it only triggers the debounce function, but the validation won't work.
I've tried that approach when I wanted to have the debounce effect on inputs, but it does not trigger the validation as I thought it would be. The function we passed to the debounce would trigger,
but the validation functions did not work.
I've gone down that path for a few days before I took another approach with the manual `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`trigger`}</code>{` API.`}</p>
    <h2>{`Bonus: How to disable the submit button when there are one or more invalid fields`}</h2>
    <p>{`We need to have an object state to keep track of whether each field is valid or not, and then we have a variable to keep an eye on all the fields`}<br parentName="p"></br>{`
`}{`To know if all properties in an object are truthy, we can use javascript `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/Object/keys"
      }}>{`Object.keys`}</a>{` and `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/every"
      }}>{`every`}</a>{`.`}</p>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Object.keys`}</code>{` will return an array of the object values and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`every`}</code>{` will return true if each element in the array passes the callback function. That means that if one or more fields in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`isValid`}</code>{` object are false, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`isFormValid`}</code>{` will be `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`false`}</code>{`.`}</p>
    <p>{`In our example, we have `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`username`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`email`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`isValid`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` setIsValid`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` React`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`useState`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` email`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` username`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` isFormValid `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Object`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`values`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`isValid`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`every`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`val`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` val`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`The submit button will look like below:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`button type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"submit"`}</span>{` disabled`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`isFormValid`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  Submit
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`button`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span></code></pre></div>
    <p>{`so now, the submit button will have a disabled state depending on the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`isFormValid`}</code>{`. The next step is to know when to update the state.`}</p>
    <p>{`If you read the document carefully about the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`trigger`}</code>{` API on their website, you will notice that it returns a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Promise<boolean>`}</code>{`, which means eventually it will tell you whether the field is valid or not.
We can make use of that and update the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`isValid`}</code>{` object state right after we trigger the validations.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// Some lines are omitted`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`Example`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`form`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`input
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"email"`}</span>{`
        onChange`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`debounce`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`async`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
          `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` result `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`await`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`trigger`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'email'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`          `}<span parentName="span" {...{
              "className": "token function"
            }}>{`setIsValid`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token parameter"
            }}>{`prevState`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`...`}</span>{`prevState`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` email`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` result `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{`        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`500`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`input
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"username"`}</span>{`
        onChange`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`debounce`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`async`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
          `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` result `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`await`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`trigger`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'username'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`          `}<span parentName="span" {...{
              "className": "token function"
            }}>{`setIsValid`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token parameter"
            }}>{`prevState`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`...`}</span>{`prevState`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` username`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` result `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{`        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`500`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`form`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h2>{`Final code sample`}</h2>
    <div>
      <iframe src="https://codesandbox.io/embed/react-hook-form-debounce-onchange-forked-qypd5?fontsize=14&hidenavigation=1&theme=dark" style={{
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      }} title="React Hook  Form -  Debounce onChange (forked)" allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking" sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      